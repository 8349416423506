<template>
    <div class="container-form">
        <template v-if="this.loaded && this.loadedProc && this.loadedObj">
            <div class="form-tbf">
                <!-- Rights Objectives -->
                <template v-if="soft_objectives && soft_objectives.is_active">
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && !$mq.above(600) ? 'w-100' : 'w-50'">
                        <div class="label-header">
                            <label class="label">{{ $t('create-user.objectives_rights') }}</label>
                            <div v-if="$v.selectedRightObj.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        </div>
                        <div class="input-box" v-bind:class="{has_error: $v.selectedRightObj.$error}">
                            <div class="icon-left"><icon-gears /></div>
                            <multiselect 
                            v-model="selectedRightObj"
                            :options="optionsRightsObj"
                            :allow-empty="false"
                            :show-labels="false"
                            :multiple="false" 
                            :close-on-select="true"
                            :disabled="user.right == 'master_admin' ? true : false"
                            >
                                <template slot="placeholder" slot-scope="props">
                                    <span class="text">{{$t('profile.choose_rights')}}</span>
                                </template>
                                <template slot="option" slot-scope="props">{{ $t(`profile.rights_name.${props.option.name}`) }}</template>
                                <template slot="singleLabel" slot-scope="{ option }">{{ $t(`profile.rights_name.${option.name}`) }}</template>
                                <template slot="noResult">{{ $t('general.no_result') }}</template>
                                <template slot="noOptions">{{ $t('general.empty_list') }}</template>
                            </multiselect>
                        </div>
                    </div>

                    <div class="delimeter-form"></div>
                </template>

                <!-- Rights Procedure -->
                <template v-if="soft_procedures && soft_procedures.is_active">
                    <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && !$mq.above(600) ? 'w-100' : 'w-50'">
                        <div class="label-header">
                            <label class="label">{{ $t('create-user.procedures_rights') }}</label>
                            <div v-if="$v.selectedRightProc.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        </div>
                        <div class="input-box" v-bind:class="{has_error: $v.selectedRightProc.$error}">
                            <div class="icon-left"><icon-gears /></div>
                            <multiselect 
                            v-model="selectedRightProc"
                            :options="optionsRightsProc"
                            :allow-empty="false"
                            :show-labels="false"
                            :multiple="false" 
                            :close-on-select="true"
                            :disabled="user.right == 'master_admin' ? true : false"
                            track-by="name"
                            >
                                <template slot="placeholder" slot-scope="props">
                                    <span class="text">{{$t('profile.choose_rights')}}</span>
                                </template>
                                <template slot="option" slot-scope="props">{{ $t(`profile.rights_name.${props.option.name}`) }}</template>
                                <template slot="singleLabel" slot-scope="{ option }">{{ $t(`profile.rights_name.${option.name}`) }}</template>
                                <template slot="noResult">{{ $t('general.no_result') }}</template>
                                <template slot="noOptions">{{ $t('general.empty_list') }}</template>
                            </multiselect>
                        </div>
                    </div>

                    <template v-if="selectedRightProc.name == 'employee'">
                        <div class="delimeter-form"></div>

                        <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && !$mq.above(600) ? 'w-100' : 'w-50'">
                            <div class="label-header">
                                <label class="label">{{$t('create-user.processes_special_rights')}}</label>
                            </div>
                            <div class="input-box bg-white">
                                <div class="icon-left"><icon-chart /></div>
                                <multiselect 
                                    class="hide-tags multiselect_checkbox"
                                    v-bind:class="{populate: selectedProcesses.length}"
                                    v-model="selectedProcesses"
                                    :options="optionsProcesses"
                                    :allow-empty="true"
                                    :show-labels="false"
                                    :multiple="true" 
                                    :close-on-select="false"
                                    track-by="id" 
                                    label="name"
                                    >
                                    <template slot="placeholder" slot-scope="props">
                                        <span class="text">
                                            {{$t('create-user.choose_processes')}}
                                        </span>
                                    </template>
                                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                                        <span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
                                        <span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <div class="checkbox">
                                            <div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
                                        </div>
                                    </template>
                                    <template slot="noResult">{{ $t('general.no_result') }}</template>
                                    <template slot="noOptions">{{ $t('general.empty_list') }}</template>
                                </multiselect>
                            </div>
                        </div>
                        
                        <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && !$mq.above(600) ? 'w-100' : 'w-50'">
                            <div class="label-header">
                                <label class="label">{{$t('create-user.procedures_special_rights')}}</label>
                            </div>
                            <div class="input-box bg-white">
                                <div class="icon-left"><icon-role /></div>
                                <multiselect 
                                    class="hide-tags multiselect_checkbox"
                                    v-bind:class="{populate: selectedRoles.length}"
                                    v-model="selectedRoles"
                                    :options="optionsRoles"
                                    :allow-empty="true"
                                    :show-labels="false"
                                    :multiple="true" 
                                    :close-on-select="false"
                                    track-by="id" 
                                    label="name"
                                    >
                                    <template slot="placeholder" slot-scope="props">
                                        <span class="text">
                                            {{$t('create-user.choose_roles')}}
                                        </span>
                                    </template>
                                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                                        <span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
                                        <span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <div class="checkbox">
                                            <div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
                                        </div>
                                    </template>
                                    <template slot="noResult">{{ $t('general.no_result') }}</template>
                                    <template slot="noOptions">{{ $t('general.empty_list') }}</template>
                                </multiselect>
                            </div>
                        </div>
                    </template>

                    <div class="delimeter-form"></div>
                </template>

                <!-- Rights Org -->
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && !$mq.above(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ soft_organigram ? $t('create-user.organigram_rights') : $t('create-user.edit_users_rights') }}</label>
                        <div v-if="$v.selectedRightOrg.$error" class="error-msg">{{ $t('validator.required') }}</div>
                    </div>
                    <div class="input-box" v-bind:class="{has_error: $v.selectedRightOrg.$error}">
                        <div class="icon-left"><icon-gears /></div>
                        <multiselect 
                        v-model="selectedRightOrg"
                        :options="optionsRightsOrg"
                        :allow-empty="false"
                        :show-labels="false"
                        :multiple="false" 
                        :close-on-select="true"
                        :disabled="user.right == 'master_admin' ? true : false"
                        track-by="name"
                        @select="handleProjectManager"
                        >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">{{$t('profile.choose_rights')}}</span>
                            </template>
                            <template slot="option" slot-scope="props">{{ soft_organigram ? $t(`profile.rights_name.${props.option.name}`) : $t(`create-user.custom_response.${props.option.name}`) }}</template>
                            <template slot="singleLabel" slot-scope="{ option }">{{ soft_organigram ? $t(`profile.rights_name.${option.name}`) : $t(`create-user.custom_response.${option.name}`) }}</template>
                            <template slot="noResult">{{ $t('general.no_result') }}</template>
                            <template slot="noOptions">{{ $t('general.empty_list') }}</template>
                        </multiselect>
                    </div>
                </div>

                <template v-if="soft_organigram">
                    <div class="input-group" :class="[$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50']" >
                        <div class="label-header">
                            <label class="label">{{ $t('create-user.right-evaluate-responsibility') }}</label>
                        </div>
                        <div class="input-box bor-0">
                            <div class="checkbox-list">
                                <div class="checkbox-item m-4" @click="evaluate_past_responsibility = 0" v-bind:class="{active: evaluate_past_responsibility === 0}">
                                    <div class="name">{{ $t('create-user.no') }}</div>
                                </div>
                                <div class="checkbox-item m-4" @click="evaluate_past_responsibility = 1" v-bind:class="{active: evaluate_past_responsibility === 1}">
                                    <div class="name">{{ $t('create-user.yes') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="input-group" :class="[$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50']" >
                        <div class="label-header">
                            <label class="label">{{ $t('create-user.right-project-manager') }}</label>
                        </div>
                        <div class="input-box bor-0">
                            <div class="checkbox-list">
                                <div class="checkbox-item m-4" @click="project_manager = 0" v-bind:class="{active: project_manager === 0}">
                                    <div class="name">{{ $t('create-user.no') }}</div>
                                </div>
                                <div class="checkbox-item m-4" @click="project_manager = 1" v-bind:class="{active: project_manager === 1}">
                                    <div class="name">{{ $t('create-user.yes') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="form-submit">
                <div class="error-msg" v-show="error_message != ''">{{ error_message }}</div>
                <div class="success-msg" v-show="success_message != ''">{{ success_message }}</div>
    
                <div class="actions-steps">
                    <button class="btn-tbf white only-icon prev" @click="changeStepFunction('prevStep')">
                        <div class="icon"><icon-arrow /></div>
                    </button>
                    <button class="btn-tbf white only-icon" @click="changeStepFunction('nextStep')">
                        <div class="icon"><icon-arrow /></div>
                    </button>
                </div>
    
                <div class="action-form">
                    <button id="buttonSubmit" class="btn-tbf blue" @click="saveAction()">
                        <div class="loader-spin" v-if="loadedSubmit"></div>
                        <span class="text" v-else>{{ $t('general.update') }}</span>
                    </button>
                </div>
            </div>
        </template>
        <LoaderStepRights v-else />
    </div>
</template>

<script>
import IconPeople from '@/components/Icons/User'
import IconClose from '@/components/Icons/Close'
import IconArrow from '@/components/Icons/AngleRight'
import IconBriefcase from '@/components/Icons/Briefcase'
import IconDepartment from '@/components/Icons/Department'
import IconBuilding from '@/components/Icons/Building'
import IconWorkSchedule from '@/components/Icons/WorkSchedule'
import IconGears from '@/components/Icons/Gears'
import IconCalendar from '@/components/Icons/Calendar'
import IconClock from '@/components/Icons/Clock'
import IconChart from '@/components/Icons/Chart'
import IconRole from '@/components/Icons/Role'
import LoaderStepRights from '@/components/PagesLoaders/CreateUserStepRights'

import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
    components: {
        IconClose,
        IconPeople,
        IconArrow,
        IconBriefcase,
        IconDepartment,
        IconBuilding,
        IconWorkSchedule,
        IconGears,
        IconCalendar,
        IconClock,
        IconChart,
        IconRole,
        LoaderStepRights
    },
    props: {
        user: Object
    },
    computed: {
        soft_procedures() {
            return this.$store.getters['applications/getApplication']('Proceduri');
        },
        soft_objectives() {
            return this.$store.getters['applications/getApplication']('Obiective');
        },
        soft_organigram() {
            return this.$store.getters['applications/getApplication']('Organigrama');
        }
    },
    data() {
        return {
            loaded: false,
            loadedProc: false,
            loadedObj: false,
            loadedSubmit: false,
            error_message: '',
            success_message: '',
            errorsBe: [],
            initialData: '{ "evaluate_past_responsibility": "", "project_manager": "", "selectedRightOrg": "", "selectedRightObj": "", "selectedRightProc": ""}',
            evaluate_past_responsibility: 1,
            project_manager: 0,
            selectedRightOrg: '',
            optionsRightsOrg: [ {name: 'admin'}, {name: 'employee'}],
            selectedRightProc: '',
            optionsRightsProc: [ {name: 'admin'}, {name: 'employee'}, { name: 'auditor'}],
            selectedRightObj: '',
            optionsRightsObj: [],
            optionsRoles: [],
            selectedRoles: [],
            optionsProcesses: [],
            selectedProcesses: [],
        }
    },
    validations: {
        selectedRightOrg: { required },
        selectedRightProc: { required: requiredIf(function () {
            return this.soft_procedures && this.soft_procedures.is_active;
        }) },
        selectedRightObj: { required: requiredIf(function () {
            return this.soft_objectives && this.soft_objectives.is_active;
        }) },
    },
    beforeDestroy() {
        this.$root.$off("user_form_change_step");
    },
    async mounted() {
        if(this.soft_procedures && this.soft_procedures.is_active && this.$route.params.slug) { await this.getFiltersData(); }
        this.handleOrganigramRights();
        if(this.soft_procedures && this.soft_procedures.is_active && this.$route.params.slug) { this.handleProceduresRights(); } else { this.loadedProc = true; }
        if(this.soft_objectives && this.soft_objectives.is_active && this.$route.params.slug) { this.handleObjectiveRights(); } else { this.loadedObj = true; }

        await this.$nextTick(() => {
            this.populateUserData();
        });
        
        this.$root.$on('user_form_change_step', (modeSave, changeData) => {
            if(modeSave == 'save') {
                this.saveAction(changeData);
            } else {
                if(!changeData.step) {
                    this.$emit(changeData.direction);
                } else {
                    this.$emit('goToStep', changeData.step)
                }
            }
        });
    },
    methods: {
        showModal(type, data = false){
            this.$root.$emit('open_modal', type, data);
        },
        async getFiltersData(){
            await axios.get( `${process.env.VUE_APP_PROCEDURES_LINK}/api/instances/${this.$auth.user().master_instance_id}/filter/external`, { params: {roles: true, processes: true}})
            .then(({data}) => {
                this.optionsRoles = data.data.roles
                this.optionsProcesses = data.data.processes
            })
        },
        populateUserData() {
            this.$nextTick(() => {
                this.loaded = true;
                this.evaluate_past_responsibility = this.user.evaluate_past_responsibility;
                this.project_manager = this.user.project_manager;

                var currentData = JSON.parse(this.initialData);
                currentData.evaluate_past_responsibility = this.user.evaluate_past_responsibility;
                currentData.project_manager = this.user.project_manager;
                currentData.selectedRightOrg = currentData.selectedRightOrg ? currentData.selectedRightOrg : (this.selectedRightOrg ? this.selectedRightOrg.name : '');
                currentData.selectedRightObj = currentData.selectedRightObj ? currentData.selectedRightObj : (this.selectedRightObj ? this.selectedRightObj.id : "");
                currentData.selectedRightProc = currentData.selectedRightProc ? currentData.selectedRightProc : ( this.selectedRightProc ? this.selectedRightProc.name : "" );
                currentData.selectedProcesses = currentData.selectedProcesses ? currentData.selectedProcesses : this.selectedProcesses;
                currentData.selectedRoles = currentData.selectedRoles ? currentData.selectedRoles : this.selectedRoles;

                this.initialData = JSON.stringify(currentData);
            });
        },
        async saveAction(changeStep = false, withRedirect = false) {
            var btnSubmit = document.getElementById('buttonSubmit');
            btnSubmit.disabled = true;
            this.loadedSubmit = true;

            this.error_message = '';

            this.$v.$touch()
            if(!this.$v.$invalid){
                // Object form data for organigram
                let objDataORG = {};
                objDataORG.right = this.selectedRightOrg.name;
                objDataORG.evaluate_past_responsibility = this.evaluate_past_responsibility ? this.evaluate_past_responsibility : 0;
                objDataORG.project_manager = this.project_manager ? this.project_manager : 0;

                // Object form data for objectiv
                let objDataOBT = {};
                objDataOBT.role_id = this.selectedRightObj.id;

                // Object form data for procedures
                let objDataProcedures = {};
                objDataProcedures.right = this.selectedRightProc.name;

                let objDataRightsProcedures = {
                    procedure_role_admin: this.selectedRoles.length ? this.selectedRoles.map(function(a) {return a.id;}) : [],
                    process_admin: this.selectedProcesses.length ? this.selectedProcesses.map(function(a) {return a.id;}) : []
                };

                if(this.soft_procedures && this.soft_procedures.is_active) { this.updateUserProcedures(objDataProcedures, objDataRightsProcedures) }
                if(this.soft_objectives && this.soft_objectives.is_active) { this.updateUserObjective(objDataOBT) }
                this.updateUserOrganigram(objDataORG, changeStep, withRedirect)
            } else {
                this.loadedSubmit = false;
                btnSubmit.disabled = false;
            }
        },
        updateUserOrganigram(objData, changeStep, withRedirect){
            var btnSubmit = document.getElementById('buttonSubmit');

            axios.patch(`/users/${this.user.slug}`, objData)
            .then(({data}) => {
                this.$parent.userData = data.data;
                this.loadedSubmit = false;
                btnSubmit.disabled = false;

                this.success_message = this.$t('create-user.success_update_msg');
                setTimeout(() => {
                    this.success_message = '';
                }, 3000);

                if(changeStep) {
                    this.$nextTick(() => {
                        if(!changeStep.step) {
                            this.$emit(changeStep.direction);
                        } else {
                            this.$emit('goToStep', changeStep.step)
                        }
                    });
                }

                if(withRedirect) {
                    // if(this.selectedVacantJob) {
                        // this.showModal('vacant_job_duration', { vacantJob: this.selectedVacantJob, userSlug: data.data.slug } )
                    // } else {
                        if(this.$route.query.department_id){
                            this.$router.push({name: 'company'})
                        }else{
                            this.$router.push({name: 'user-show', params: { slug: data.data.slug }})
                        }
                    // }
                }
            })
            .catch(error => {
                this.errorsBe = error.response.data.errors;
                this.loadedSubmit = false;
                btnSubmit.disabled = false;

                setTimeout(()=>{
                    if(error.response.status == 500){
                        this.error_message = this.$t('error.500')
                    }
                }, 300)
            })
            .finally(() => { 
                var currentData = JSON.parse(this.initialData);

                currentData.selectedRightOrg = this.selectedRightOrg.name;
                currentData.evaluate_past_responsibility = this.user.evaluate_past_responsibility;
                currentData.project_manager = this.user.project_manager;

                this.initialData = JSON.stringify(currentData);
            })
        },
        updateUserObjective(objData) {
            axios.patch(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/people/${this.$route.params.slug}`, objData)
            .then(() => {
                var currentData = JSON.parse(this.initialData);

                currentData.selectedRightObj = this.selectedRightObj.id;

                this.initialData = JSON.stringify(currentData);
            })
        },
        async updateUserProcedures(objData, objSpecialRights) {
            axios.patch(`${process.env.VUE_APP_PROCEDURES_LINK}/api/users/${this.$route.params.slug}`, objData)
            .then(() => {
                var currentData = JSON.parse(this.initialData);
                currentData.selectedRightProc = this.selectedRightProc.name;

                this.initialData = JSON.stringify(currentData);
            })

            axios.post(`${process.env.VUE_APP_PROCEDURES_LINK}/api/special-rights/${this.user.slug}/sync`, objSpecialRights)
            .then(() => {
                var currentData = JSON.parse(this.initialData);
                currentData.selectedProcesses = this.selectedProcesses;
                currentData.selectedRoles = this.selectedRoles;

                this.initialData = JSON.stringify(currentData);
            })
        },
        changeStepFunction(direction, stepNo = false) {

            // Create a JSon stringify with current data form
            var currentObject = JSON.stringify({
                evaluate_past_responsibility: this.evaluate_past_responsibility,
                project_manager: this.project_manager,
                selectedRightOrg: this.selectedRightOrg.name,
                selectedRightObj: this.selectedRightObj ? this.selectedRightObj.id : "",
                selectedRightProc: this.selectedRightProc ? this.selectedRightProc.name : "",
                selectedProcesses: this.selectedProcesses,
                selectedRoles: this.selectedRoles
            });

            // Check diference between initial data and current
            if(this.initialData === currentObject || ( !this.loaded || !this.loadedProc || !this.loadedObj) ) { 
                if(direction) { 
                    this.$emit(direction);
                } else if(stepNo) {
                    this.$emit('goToStep', stepNo)
                }
            } else {
                this.$root.$emit('open_modal', 'confirm_change_step', { direction: direction, step: stepNo });
            }
        },
        handleOrganigramRights() {
            this.optionsRightsOrg.map( (el, key) => {
                if(el.name == 'admin') {
                    this.optionsRightsOrg[key].$isDisabled = ['master_admin', 'admin'].includes(this.$auth.user().right) ? false : true;
                }
            })

            if(this.user.right && this.user.right == 'master_admin'){
                var masterAdminRight = {name: 'master_admin'};
                this.optionsRightsOrg.push(masterAdminRight);
                this.selectedRightOrg = masterAdminRight;
            }else{
                this.selectedRightOrg = this.optionsRightsOrg.find(el => el.name == this.user.right);
            }

            var currentData = JSON.parse(this.initialData);
            currentData.selectedRightOrg = this.selectedRightOrg.name;
            currentData.project_manager = this.user.project_manager;

            this.initialData = JSON.stringify(currentData);
        },
        handleProceduresRights() {
            axios.get(`${process.env.VUE_APP_PROCEDURES_LINK}/api/users/${this.$route.params.slug}/edit`)
            .then(({data}) => {
                this.optionsRightsProc.map( (el, key) => {
                    switch (el.name) {
                        case 'admin':
                            this.optionsRightsProc[key].$isDisabled = ['master_admin', 'admin'].includes(this.$auth.user().right) ? false : true;
                            break;
                        case 'auditor':
                            this.optionsRightsProc[key].$isDisabled = ['master_admin', 'admin'].includes(this.$auth.user().right) ? false : true;
                            break;
                    }
                })
                
                if(data.data.right == 'master_admin'){ 
                    this.optionsRightsProc.push( {name: 'master_admin'} );
                    this.selectedRightProc = {name: 'master_admin'};
                } else {
                    this.selectedRightProc = this.optionsRightsProc.find(el => el.name == data.data.right);
                }

                if(data.data.special_rights && data.data.special_rights.length)  {
                    if(data.data.special_rights.find(el => el.type == 'process_admin')) {
                        let arrayProcesses  = data.data.special_rights.find(el => el.type == 'process_admin').entity_ids;
                        this.selectedProcesses = this.optionsProcesses.filter(el => arrayProcesses.includes(el.id));
                    }
                    
                    if(data.data.special_rights.find(el => el.type == 'procedure_role_admin')){
                        let arrayRoles  = data.data.special_rights.find(el => el.type == 'procedure_role_admin').entity_ids;
                        this.selectedRoles = this.optionsRoles.filter(el => arrayRoles.includes(el.id));
                    }
                }

            })
            .finally(() => {
                var currentData = JSON.parse(this.initialData);
                currentData.selectedRightProc = this.selectedRightProc.name;
                currentData.selectedProcesses = this.selectedProcesses;
                currentData.selectedRoles = this.selectedRoles;

                this.initialData = JSON.stringify(currentData);

                this.loadedProc = true;
            });
        },
        async handleObjectiveRights() {
            await axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/roles`)
            .then(({data}) => {
                this.optionsRightsObj = data.data;
            })

            await axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/people/${this.$route.params.slug}/edit`)
            .then(({data}) => {
                this.optionsRightsObj.map( (el, key) => {
                    switch (el.name) {
                        case 'admin':
                            this.optionsRightsObj[key].$isDisabled = ['master_admin', 'admin'].includes(this.$auth.user().right) ? false : true;
                            break;
                        case 'manager':
                            this.optionsRightsObj[key].$isDisabled = ['master_admin', 'admin'].includes(this.$auth.user().right) ? false : true;
                            break;
                    }
                })
                
                if(data.data.is_entrepreneur){ 
                    var masterAdminCloneAdmin = {id: 1, name: 'master_admin'};
                    this.optionsRightsObj.push( masterAdminCloneAdmin );
                    this.selectedRightObj = masterAdminCloneAdmin;
                } else {
                    this.selectedRightObj = this.optionsRightsObj.find(el => el.id == data.data.role_id);
                }
            })
            .finally(() => {
                var currentData = JSON.parse(this.initialData);
                currentData.selectedRightObj = this.selectedRightObj.id;

                this.initialData = JSON.stringify(currentData);

                this.loadedObj = true;
            });
        },
        handleProjectManager(selectedOption) {
            this.project_manager = selectedOption.name == 'admin' ? 1 : this.project_manager;
        }
    }
}
</script>